export const sitePrefix = process.env.REACT_APP_PRISMIC_PREFIX;
export const apiEndpoint = process.env.REACT_APP_PRISMIC_API_ENDPOINT;
export const globalApiEndpoint =
  "https://grainger-global.cdn.prismic.io/api/v2";
export const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
export const accessToken = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;
export const walkscoreAccessToken =
  process.env.REACT_APP_WALKSCORE_ACCESS_TOKEN;

export const GA_TRACKING = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
export const SALESFORCE_ESTATE = process.env.REACT_APP_SALESFORCE_ESTATE;
export const SALESFORCE_FIELD_IDS = process.env.REACT_APP_SALESFORCE_FIELD_IDS;
export const SALESFORCE_FIELD_VALUES =
  process.env.REACT_APP_SALESFORCE_FIELD_VALUES;
export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
export const REACT_APP_HIDE_LOCATION = process.env.REACT_APP_HIDE_LOCATION === "true";

export const oneTrustProps = {
  testScriptSource:
    "https://optanon.blob.core.windows.net/consent/f5c1c230-8740-47a3-b6d8-298f3dc560ff-test.js",
  scriptSource:
    "https://cdn.cookielaw.org/consent/f5c1c230-8740-47a3-b6d8-298f3dc560ff.js",
  isProduction: process.env.NODE_ENV === "production",
};

export const poiColours = {
  food: "#E5A01D",
  shops: "#62B1AD",
  services: "#F36F4A",
  transport: "#4E8382",
};

export const poiPlaces = {
  food: [
    {
      placeId: "ChIJ5SP2aGmue0gRHg0kpBn0jPo",
      description:
        "Offering an unrivalled selection of craft ales, signature ‘docktails’ and relaxed dining. The place to be when it comes to live music.",
    }, // The Dockyard
    {
      placeId: "ChIJeYBUcNuxe0gReoT1OgYZ3Co",
      description:
        "Piazza dreaming on Salfords Exchange Quay. Serving proper Italian coffee, breakfast and lunch.",
    }, // Lupo
    {
      placeId: "ChIJfT8zdmyue0gRa64Uox7FIys",
      description:
        "Cheeky Nandos anyone? Enjoy flame grilled chicken in a famous peri-peri sauce at this popular chain restaurant.",
    }, // Nandos
    {
      placeId: "ChIJPcPNJz6ue0gRy8nXbWuI7-Q",
      description:
        "Serving irresistible handmade Italian pizza plus lots of other favourites.",
    }, // Pizza Express
  ],
  shops: [
    {
      placeId: "ChIJlfl3hmyue0gRM05eoCp7rgQ",
      description:
        "An outstanding mix of high street and designer labels at up to 70% off. Including Gap, Next & Molton Brown.",
      displayName: "Lowry Outlet",
    }, // Lowry
    {
      placeId: "ChIJNbA1tRaue0gRxs00Jki8oMc",
      description:
        "Local neighbourhood convenience store. Open everyday until midnight.",
    }, // Tesco
    {
      placeId: "ChIJRyNiSxGue0gREqt-6p7FlsE",
      description:
        "Local convinience store selling a wide range of basic grocery items.",
    }, // East West Express
  ],
  services: [
    {
      placeId: "ChIJscfDZRGue0gR1BxtHCBaL90",
      description:
        "Cash withdrawal point available outside the local Tesco Express.",
    }, // ATM
    {
      placeId: "ChIJDcheDBeue0gR15DKYZychCk",
      description:
        "Local dentist surgery offering a comprehensive range of NHS and private services.",
      displayName: "Ravat and Ray dental",
    }, // Ravat and Ray
    {
      placeId: "ChIJS3JcPRaue0gRsraaoV2i2Tc",
      description:
        "Petrol station including convenience store and Subway sandwich shop next door.",
    }, // Shell
    {
      placeId: "ChIJp5vRAA6ue0gROG4ZAGmUgAA",
      description:
        "Send and collect letters and parcels. Also offers road tax, foreign exchange and other services.",
      displayName: "Post Office",
    }, // Post Office
  ],
  transport: [
    {
      placeId: "ChIJD9lPIRKue0gRTtzBa0rw2Us",
      description:
        "Short bus ride passing through The Quays and towards Pendleton where you will be able to find a large Tesco Extra and Salford Shopping Centre.",
      displayName: "Clippers Quay (Bus Stop)",
    }, // Clippers Quay Bus stop
    {
      placeId: "ChIJOda_HRKue0gRVBnSehJAHW8",
      description:
        "A short bus ride to Manchester University, Manchester Royal Infirmary and Stretford.",
      displayName: "Exchange Quay (Bus Stop)",
    }, // Exchange Quay
    {
      placeId: "ChIJ_SuxkhGue0gRDYSu3DxdA5Q",
      description:
        "Located directly opposite Clippers Quay. Providing links to the likes of Media City, Deansgate, City Centre, Piccadilly and Etihad Campus.",
      displayName: "Exchange Quay (Tram Stop)",
    }, // Exchange Quay Tram
  ],
};

export const INSTAGRAM_FEED = {
  data: {
    data: [
      {
        id: "1750857847786223573_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/5fe7cba2a775f440d781a47d8d3c2fde/5B955BE4/t51.2885-15/s150x150/e35/c1.0.1078.1078/29417731_441605189605924_2596975486177378304_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 319,
            url:
              "https://scontent.cdninstagram.com/vp/d3703a4a3a58dab57cf9041d3455f14d/5B8A7EB1/t51.2885-15/s320x320/e35/29417731_441605189605924_2596975486177378304_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 638,
            url:
              "https://scontent.cdninstagram.com/vp/c6243e4ed508fb982a4e406806426705/5BC500F2/t51.2885-15/s640x640/sh0.08/e35/29417731_441605189605924_2596975486177378304_n.jpg",
          },
        },
        created_time: "1522938541",
        caption: {
          id: "17936316517043211",
          text:
            "Moving home shouldn't be a hassle, so we're offering 1 year's free Zipcar membership, plus £105 driving credit, when you put down a holding deposit on a 2 bed at Argo Apartments between 3 April and 18 April! Visit www.argoapartments.co.uk to learn more! #rentingredefined #keepmoving @zipcaruk",
          created_time: "1522938541",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 8 },
        tags: ["rentingredefined", "keepmoving"],
        filter: "Normal",
        comments: { count: 0 },
        type: "image",
        link: "https://www.instagram.com/p/BhMS--lgLfV/",
        location: null,
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1749266168742517002_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/10b440a8856c17a1e33226ff7fff3c9b/5B8B3946/t51.2885-15/s150x150/e35/29740623_2042194502724693_8774407981438599168_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/6e09564e8e1fd23391feeab0c3e23b6b/5B821A76/t51.2885-15/s320x320/e35/29740623_2042194502724693_8774407981438599168_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/6c03c884bd972379ede6333a84ca1808/5BC41D3B/t51.2885-15/s640x640/sh0.08/e35/29740623_2042194502724693_8774407981438599168_n.jpg",
          },
        },
        created_time: "1522748798",
        caption: {
          id: "17862277027235329",
          text:
            "Can't face the office this morning? We're all for holding meetings from the comfort of home! That's why we've built in a meeting space for residents to use at Argo Apartments #rentingredefined #backtowork",
          created_time: "1522748798",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 25 },
        tags: ["backtowork", "rentingredefined"],
        filter: "Sierra",
        comments: { count: 3 },
        type: "image",
        link: "https://www.instagram.com/p/BhGpE_9gZkK/",
        location: {
          latitude: 51.507114863624,
          longitude: -0.12731805236353,
          name: "London, United Kingdom",
          id: 213385402,
        },
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1745848573223506069_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/13baad4687b777ac22da96a6ea6c802a/5B8F351F/t51.2885-15/s150x150/e35/c0.0.666.666/29095919_174634640013221_4139179052533547008_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 319,
            url:
              "https://scontent.cdninstagram.com/vp/b96c5a3af2e463765c0037486a698cd8/5B7E4DE2/t51.2885-15/s320x320/e35/29095919_174634640013221_4139179052533547008_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 639,
            url:
              "https://scontent.cdninstagram.com/vp/ad939fb12572ad1f8552cf401251f8ef/5B96FBA1/t51.2885-15/s640x640/sh0.08/e35/29095919_174634640013221_4139179052533547008_n.jpg",
          },
        },
        created_time: "1522341389",
        caption: {
          id: "17843301100262675",
          text:
            "Somewhere for friends and family to stay over Easter weekend (and it's not the sofa!). Residents at Argo can invite guests to book our fabulous guest suite 😴#rentingredefined #argoapartments",
          created_time: "1522341389",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 22 },
        tags: [
          "guestsuite",
          "apartmentsforrent",
          "professionallandlord",
          "argoapartments",
          "london",
          "homefromhome",
          "rentingredefined",
          "rent",
          "guestroom",
          "londonlife",
          "easterweekend",
          "londoner",
          "betterrenting",
          "easterguest",
          "notahotel",
          "betterthanahotel",
          "easterbreak",
        ],
        filter: "Normal",
        comments: { count: 2 },
        type: "image",
        link: "https://www.instagram.com/p/Bg6gAcGnBCV/",
        location: {
          latitude: 51.507114863624,
          longitude: -0.12731805236353,
          name: "London, United Kingdom",
          id: 213385402,
        },
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1740021982311127287_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/d54eeb23eb9c4abf7e532983f4ccc990/5B861ED1/t51.2885-15/s150x150/e35/29088881_193583317922409_8207577851482865664_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/dd6eda892f51506b1ec2804ad8634fa5/5B82D096/t51.2885-15/s320x320/e35/29088881_193583317922409_8207577851482865664_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/389564edfc3a80696a2030664b055460/5B8DE2B4/t51.2885-15/e35/29088881_193583317922409_8207577851482865664_n.jpg",
          },
        },
        created_time: "1521646806",
        caption: {
          id: "17923169725073626",
          text:
            "A friendly face to take the hassle out of renting. Every Grainger home has a named Property Manager. It's all part of our professional landlord service 😊#rentingredefined #customerfeedback",
          created_time: "1521646806",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 20 },
        tags: ["customerfeedback", "rentingredefined"],
        filter: "Normal",
        comments: { count: 3 },
        type: "image",
        link: "https://www.instagram.com/p/BglzMYdBpj3/",
        location: null,
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1735702863373707461_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/042143c801b054fe9a4b3f17dc06c89c/5B813485/t51.2885-15/s150x150/e35/28435022_625638841116275_1231059277687291904_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/1e07948ff0550eb6a2cb246f5d4d2f19/5B8AA6C2/t51.2885-15/s320x320/e35/28435022_625638841116275_1231059277687291904_n.jpg",
          },
          standard_resolution: {
            width: 480,
            height: 480,
            url:
              "https://scontent.cdninstagram.com/vp/51c5b52aef5868f6db689691201c52af/5B86B6E0/t51.2885-15/e35/28435022_625638841116275_1231059277687291904_n.jpg",
          },
        },
        created_time: "1521131926",
        caption: {
          id: "17904749134180889",
          text:
            "Well we feel totally spoilt today ☺️Thank you to our new Argo resident who gave us this lovely gift #lifesaboxofchocolates #rentingredefined",
          created_time: "1521131926",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 12 },
        tags: ["rentingredefined", "lifesaboxofchocolates"],
        filter: "Normal",
        comments: { count: 0 },
        type: "image",
        link: "https://www.instagram.com/p/BgWdI7fBKjF/",
        location: null,
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1728319194767698675_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/b39a2e0882157cd17d9b1c49f94647ce/5BC07471/t51.2885-15/s150x150/e35/28436368_173330843306236_7355166910700322816_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/1db9f198bf202902001a9c245f4660fa/5B942C36/t51.2885-15/s320x320/e35/28436368_173330843306236_7355166910700322816_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/cc942459fa14c014d47ed26ef9f4abb3/5B86AA75/t51.2885-15/s640x640/sh0.08/e35/28436368_173330843306236_7355166910700322816_n.jpg",
          },
        },
        created_time: "1520251725",
        caption: {
          id: "17887819954197062",
          text:
            "Rock into the week! We love this armchair picked out by @boxnine7 for Argo Apartments ❤️",
          created_time: "1520251725",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 20 },
        tags: [
          "rockingchair",
          "rockandroll",
          "mondaymotivation",
          "apartmentsforrent",
          "homedecor",
          "apartmentdecor",
          "homeinspo",
          "livingroom",
          "londonliving",
          "armchair",
          "mondaymood",
          "apartment",
          "london",
          "rentingredefined",
          "lounge",
        ],
        filter: "Lo-fi",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/Bf8OSaOnirz/",
        location: {
          latitude: 51.507114863624,
          longitude: -0.12731805236353,
          name: "London, United Kingdom",
          id: 213385402,
        },
        attribution: null,
        users_in_photo: [
          {
            user: { username: "boxnine7" },
            position: { x: 0.640625, y: 0.5171875 },
          },
        ],
      },
      {
        id: "1725527929125003685_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/6bdc622ce0e58e8d2c659c37b1ab0fab/5B92D127/t51.2885-15/s150x150/e35/28751606_238143490061877_4462781121477738496_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/db3aa1289f3764df79866b4ebad6406e/5B9EF160/t51.2885-15/s320x320/e35/28751606_238143490061877_4462781121477738496_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/2e3a9be28a8256a2bd39238d0290e7a6/5B820023/t51.2885-15/s640x640/sh0.08/e35/28751606_238143490061877_4462781121477738496_n.jpg",
          },
        },
        created_time: "1519918980",
        caption: {
          id: "17927825926009693",
          text:
            "Still snowy up on the Argo Apartments roof terrace. Views not bad though. Brrrrr! ❄️#rentingredefined #stormemma #london #o2arena #snowday",
          created_time: "1519918980",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 22 },
        tags: [
          "apartmentsforrent",
          "snowday",
          "londonview",
          "londoner",
          "roofterrace",
          "o2arena",
          "stormemma",
          "renting",
          "canningtown",
          "snow",
          "london",
          "rentingredefined",
        ],
        filter: "Lark",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/BfyToJhguGl/",
        location: null,
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1724799930406726357_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/78ada9555bb3e5d2cd7e1f181e03e92e/5BC391B4/t51.2885-15/s150x150/e35/c48.0.984.984/28151579_199909863938906_5035119048774385664_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 291,
            url:
              "https://scontent.cdninstagram.com/vp/61c011703c63ae45866462ee10a08e54/5B820183/t51.2885-15/s320x320/e35/28151579_199909863938906_5035119048774385664_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 583,
            url:
              "https://scontent.cdninstagram.com/vp/5fe8d2e676cac2fb3ae18ab96c34c62e/5B905CC0/t51.2885-15/s640x640/sh0.08/e35/28151579_199909863938906_5035119048774385664_n.jpg",
          },
        },
        created_time: "1519832196",
        caption: {
          id: "17927356246005934",
          text:
            "'Superfast broadband now more important than good transport links and nice neighbours' - Our Argo Apartments free superfast Wi-Fi rental offering with @virginmedia features in the Evening Standard today #rentingredefined #highfibre #digitalage",
          created_time: "1519832196",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 16 },
        tags: [
          "wifi",
          "apartmentsforrent",
          "apartments",
          "homesweethome",
          "renting",
          "eveningstandard",
          "london",
          "rentingredefined",
          "fibretothedoor",
          "superfast",
          "highfibre",
          "getconnected",
          "digitalage",
          "virginmedia",
          "needforspeed",
          "fibreoptic",
        ],
        filter: "Clarendon",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/BfvuGX4gurV/",
        location: {
          latitude: 51.507114863624,
          longitude: -0.12731805236353,
          name: "London, United Kingdom",
          id: 213385402,
        },
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1724661913620814919_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/36a31a9a19626f5e15c5877cfcced090/5B91730A/t51.2885-15/s150x150/e35/28151119_311593646032513_4930793958147096576_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/d735cff4ebd517b0654f668bec3c3e34/5B92054D/t51.2885-15/s320x320/e35/28151119_311593646032513_4930793958147096576_n.jpg",
          },
          standard_resolution: {
            width: 480,
            height: 480,
            url:
              "https://scontent.cdninstagram.com/vp/c74b351eae900029b16416e552cbf696/5B8AF16F/t51.2885-15/e35/28151119_311593646032513_4930793958147096576_n.jpg",
          },
        },
        created_time: "1519815743",
        caption: {
          id: "17902249447130355",
          text:
            "Beautiful morning to take a nice cup of coffee up to the roof terrace. Extra layers needed! 1&2 bed apartments still available to rent at Argo Apartments. Enquire today 😊☃️#rentingredefined",
          created_time: "1519815743",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 22 },
        tags: ["rentingredefined"],
        filter: "Normal",
        comments: { count: 3 },
        type: "image",
        link: "https://www.instagram.com/p/BfvOt9vA5xH/",
        location: {
          latitude: 51.514638,
          longitude: 0.0099800000000094,
          name: "London Canning Town",
          id: 823095653,
        },
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1723418150768471398_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/3d40f397569e3223499705e7bfe4fe56/5B8CD4EF/t51.2885-15/s150x150/e35/28155613_2052864831653028_1238214371079880704_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/76d131a53511731a78fd7c007471671c/5B8ABEDF/t51.2885-15/s320x320/e35/28155613_2052864831653028_1238214371079880704_n.jpg",
          },
          standard_resolution: {
            width: 480,
            height: 480,
            url:
              "https://scontent.cdninstagram.com/vp/251845ffc6a1fde037d104da3f7b69cd/5B7C44B8/t51.2885-15/e35/28155613_2052864831653028_1238214371079880704_n.jpg",
          },
        },
        created_time: "1519667475",
        caption: {
          id: "17927271949028950",
          text:
            "At Grainger we also oversee the design and build of our new homes so we can make sure they meet the needs of renters today. Today we were onsite at Clippers Quay, Salford where we're building 614 new homes for rent #rentingredefined",
          created_time: "1519667475",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 18 },
        tags: [
          "manchester",
          "apartmentsforrent",
          "rentingrevolution",
          "btr",
          "clippersquay",
          "rent",
          "professionallandord",
          "salfordquay",
          "prs",
          "rentingredefined",
          "salford",
        ],
        filter: "Valencia",
        comments: { count: 3 },
        type: "image",
        link: "https://www.instagram.com/p/Bfqz61UA-1m/",
        location: null,
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1720352416626499635_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/14de5113ebeaef950fb6b936427c6476/5BC47D81/t51.2885-15/s150x150/e35/27893682_1784622425175554_8679367270075465728_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/465acc664174c9f265b0d46997f9a745/5B7D76B1/t51.2885-15/s320x320/e35/27893682_1784622425175554_8679367270075465728_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/18434641abe08598e1912875bafbd142/5B916BFC/t51.2885-15/s640x640/sh0.08/e35/27893682_1784622425175554_8679367270075465728_n.jpg",
          },
        },
        created_time: "1519302011",
        caption: {
          id: "17912746387114673",
          text:
            "Over 50% of our homes to rent at Argo Apartments are let. If you or a friend are looking for a great place to rent, with lots of amenities and fantastic links to Canary Wharf, London Bridge and the City, enquiry today. Follow link in bio. #rentingredefined",
          created_time: "1519302011",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 25 },
        tags: [
          "canarywharf",
          "apartmentsforrent",
          "londonbridge",
          "londonrentals",
          "onthejubileeline",
          "londoner",
          "apartments",
          "modernrentals",
          "prs",
          "canningtown",
          "letting",
          "london",
          "rentingredefined",
          "rent",
          "homesweethome",
        ],
        filter: "Normal",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/Bff62h5geAz/",
        location: {
          latitude: 51.507114863624,
          longitude: -0.12731805236353,
          name: "London, United Kingdom",
          id: 213385402,
        },
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1716105459674430978_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/69a0ea2606fabae62478d3292676dbf9/5BA04FD9/t51.2885-15/s150x150/e35/27576231_192628791470520_3083353779098091520_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/fc982a543ffe416efbc1ddacc655e3cd/5B8FC89E/t51.2885-15/s320x320/e35/27576231_192628791470520_3083353779098091520_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/4c75b348923f88029fb2c3e5c0dd282c/5B8D08DD/t51.2885-15/s640x640/sh0.08/e35/27576231_192628791470520_3083353779098091520_n.jpg",
          },
        },
        created_time: "1518795734",
        caption: {
          id: "17924769568005082",
          text:
            "Have you heard about the 'British rental revolution'? We're proud to see Argo Apartments by Grainger featured in an article for The Times today. We're passionate about changing the rental experience for the better - to find out what the next generation of rental homes looks like, visit argoapartments.co.uk, 1 & 2 bed apartments available to rent now. #rentalrevolution #rentingredefined",
          created_time: "1518795734",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 25 },
        tags: [
          "rentalrevolution",
          "changeisgood",
          "apartmentsforrent",
          "news",
          "thetimes",
          "londoner",
          "betterrenting",
          "londonliving",
          "apartment",
          "londonrental",
          "london",
          "rentingredefined",
          "bricksandmortar",
          "rent",
          "homesweethome",
        ],
        filter: "Ludwig",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/BfQ1NLBAL4C/",
        location: null,
        attribution: null,
        users_in_photo: [
          {
            user: { username: "thetimes" },
            position: { x: 0.653125, y: 0.4296875 },
          },
        ],
      },
      {
        id: "1714635416486930522_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/62d7244ddccfd77a5b50e0fc585cf91b/5BC4F5EB/t51.2885-15/s150x150/e35/27576682_145077972837932_1361736818764021760_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/a3cf87360f3159d5b4e948698b693603/5B9473AC/t51.2885-15/s320x320/e35/27576682_145077972837932_1361736818764021760_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/4f7a911bccbc70691d6bdbcec8ba2daa/5B8EFBEF/t51.2885-15/s640x640/sh0.08/e35/27576682_145077972837932_1361736818764021760_n.jpg",
          },
        },
        created_time: "1518620491",
        caption: {
          id: "17911912090107758",
          text:
            "Eating out or staying in this valentines? The stylish kitchens at Argo Apartments are equipped with lovely Zanussi appliances making it the perfect place to cook up a storm for your loved one ❤️ #rentingredefined #argoapartments",
          created_time: "1518620491",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 12 },
        tags: [
          "apartmentsforrent",
          "londonrentals",
          "kitchen",
          "londoner",
          "zanussi",
          "apartments",
          "argoapartments",
          "valentinesday",
          "valentinesmeal",
          "london",
          "rentingredefined",
          "rent",
          "cookingathome",
        ],
        filter: "Normal",
        comments: { count: 2 },
        type: "image",
        link: "https://www.instagram.com/p/BfLm9OnAeha/",
        location: {
          latitude: 51.507114863624,
          longitude: -0.12731805236353,
          name: "London, United Kingdom",
          id: 213385402,
        },
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1700864442184649339_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/aeebabd24d3c6f00ba2a39837c186f54/5B845467/t51.2885-15/s150x150/e35/c49.0.666.666/26866837_138624026834995_8016795533043564544_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 278,
            url:
              "https://scontent.cdninstagram.com/vp/a4a296dd2d73794d995b284cb752b173/5BC150F0/t51.2885-15/s320x320/e35/26866837_138624026834995_8016795533043564544_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 557,
            url:
              "https://scontent.cdninstagram.com/vp/6c380b160acce76481dc9867ed853dfe/5B9E25B3/t51.2885-15/s640x640/sh0.08/e35/26866837_138624026834995_8016795533043564544_n.jpg",
          },
        },
        created_time: "1516978863",
        caption: {
          id: "17894254132148348",
          text:
            "Whether you're having a dry January, vegan January or just generally carrying on the December indulgence - it's always nice to get everyone together around the table 😊🥂🥗. That's why at Argo Apartments we  created a private hire dining room so you can extend your guest list! Follow link in bio to see video tour #rentingredefined",
          created_time: "1516978863",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 21 },
        tags: [
          "londonrentals",
          "rentalproperty",
          "tgif",
          "privatedining",
          "friends",
          "dinnergram",
          "veganuary",
          "dryjanuary",
          "dinnerparty",
          "apartment",
          "london",
          "rentingredefined",
          "rent",
          "family",
        ],
        filter: "Normal",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/BearzLXgkp7/",
        location: {
          latitude: 51.507114863624,
          longitude: -0.12731805236353,
          name: "London, United Kingdom",
          id: 213385402,
        },
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1695780764718792234_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/d091b1ceebe1db4b68c301c267883070/5B82B283/t51.2885-15/s150x150/e35/c86.0.666.666/26158544_869222719905767_7878523450405421056_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 254,
            url:
              "https://scontent.cdninstagram.com/vp/6dd7af78c83be4db72c3fa88aca26267/5BA2F093/t51.2885-15/s320x320/e35/26158544_869222719905767_7878523450405421056_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 508,
            url:
              "https://scontent.cdninstagram.com/vp/353a4751a4af7361b4a2c54aa61f037d/5B9FF0D0/t51.2885-15/s640x640/sh0.08/e35/26158544_869222719905767_7878523450405421056_n.jpg",
          },
        },
        created_time: "1516372841",
        caption: {
          id: "17906479906106731",
          text:
            "Imagine if you could rent an apartment in London and have access to a roof terrace like this? We have 1 & 2 beds available now for less than you'd think. Follow link in bio...",
          created_time: "1516372841",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 16 },
        tags: [
          "londonlife",
          "theviewfromuphere",
          "btr",
          "betterrenting",
          "roofterrace",
          "argoapartments",
          "prs",
          "londonapartment",
          "apartment",
          "london",
          "rentingredefined",
          "rent",
          "londonrooftops",
        ],
        filter: "Nashville",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/BeIn57yAP4q/",
        location: {
          latitude: 51.507114863624,
          longitude: -0.12731805236353,
          name: "London, United Kingdom",
          id: 213385402,
        },
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1695155937683183202_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/dc3c1b03d9d22a4962314b1c35be8160/5B83B8D2/t51.2885-15/s150x150/e35/25038810_1927346694196117_8094042023100678144_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/7014e3638b25cb77bfbd5f2d2e3ec555/5B7E89E2/t51.2885-15/s320x320/e35/25038810_1927346694196117_8094042023100678144_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/234b7dd04cf63dcd6ac0d9b7ae221603/5B95A1AF/t51.2885-15/s640x640/sh0.08/e35/25038810_1927346694196117_8094042023100678144_n.jpg",
          },
        },
        created_time: "1516298356",
        caption: {
          id: "17901640456084431",
          text:
            "Can anyone suggest a good book for a cold January day? The snug at Argo is the perfect place to cosy up with a book and exchange your favourite reads with neighbours.",
          created_time: "1516298356",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 15 },
        tags: [
          "relax",
          "bookswap",
          "meettheneighbours",
          "cosy",
          "londoner",
          "argoapartments",
          "goodbook",
          "bookrecommendation",
          "apartment",
          "londonapartment",
          "reading",
          "rentingredefined",
          "rent",
        ],
        filter: "Rise",
        comments: { count: 2 },
        type: "image",
        link: "https://www.instagram.com/p/BeGZ1gQgBZi/",
        location: null,
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1694417027273371740_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/6e96ce1d2eaaefe736aad2304dc14f57/5B8E774A/t51.2885-15/s150x150/e35/26180319_1966039360314760_2530752308858322944_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/11422643093fdb972b0f71c6a49f73ff/5BC1F97A/t51.2885-15/s320x320/e35/26180319_1966039360314760_2530752308858322944_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/61ecf20e63b088a654ac84e596157714/5B86631D/t51.2885-15/e35/26180319_1966039360314760_2530752308858322944_n.jpg",
          },
        },
        created_time: "1516210271",
        caption: {
          id: "17924087092000398",
          text:
            "So MUCH storage. Every bedroom at Argo comes with built in wardrobes. 1 & 2 beds available for rent now - follow link in bio. #rentingredefined",
          created_time: "1516210271",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 20 },
        tags: [
          "professionallandlord",
          "londoner",
          "builtinwardrobe",
          "londonliving",
          "furnished",
          "home",
          "apartment",
          "storage",
          "rentingredefined",
          "rent",
        ],
        filter: "Normal",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/BeDx08UALBc/",
        location: null,
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1693646636254807874_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/4c15143db2e1e0e1fb2e75044619ac0d/5B94230E/t51.2885-15/s150x150/e35/26153933_2065061927063551_6014129781308653568_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/cdc3f4220c3ab61bc80d37464d16ade4/5B89463E/t51.2885-15/s320x320/e35/26153933_2065061927063551_6014129781308653568_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/a9dd266a8774ebdebb79994f3ba5a734/5B822D59/t51.2885-15/e35/26153933_2065061927063551_6014129781308653568_n.jpg",
          },
        },
        created_time: "1516118433",
        caption: {
          id: "17905296706114393",
          text:
            "At Grainger we want to change the way people think about renting - that's why it's great to get feedback like this! #professionallandlord #rentingredefined",
          created_time: "1516118433",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 17 },
        tags: [
          "customerfeedback",
          "professionallandlord",
          "londonrentals",
          "betterrenting",
          "renting",
          "landlord",
          "apartment",
          "propertymanagement",
          "london",
          "rentingredefined",
          "rent",
          "homesweethome",
        ],
        filter: "Normal",
        comments: { count: 2 },
        type: "image",
        link: "https://www.instagram.com/p/BeBCqRxAMdC/",
        location: {
          latitude: 51.508055555556,
          longitude: -0.087777777777778,
          name: "London Bridge",
          id: 504890233,
        },
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1692878674568857637_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/1cc3785e59b1521bc5eae2b49f8b0881/5BA046F5/t51.2885-15/s150x150/e35/26071579_1527972347310232_4491595009094582272_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/ac9c324b5ce6b052dde456dc98ede137/5B8574C5/t51.2885-15/s320x320/e35/26071579_1527972347310232_4491595009094582272_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/08f6249688b985c87bd83787eb636681/5BC26088/t51.2885-15/s640x640/sh0.08/e35/26071579_1527972347310232_4491595009094582272_n.jpg",
          },
        },
        created_time: "1516026885",
        caption: {
          id: "17888490511173987",
          text:
            "We've got your connection sorted - dedicated 100mbps Virgin Media Fibre Optic Internet & Wi-Fi, included as standard for each apartment at Argo #virginmedia #virginwifi #rentingredefined #argoapartments",
          created_time: "1516026885",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 13 },
        tags: [
          "freewifi",
          "londonrentals",
          "needforspeed",
          "virginwifi",
          "londonliving",
          "argoapartments",
          "getconnected",
          "londonapartment",
          "apartment",
          "virginmedia",
          "rentingredefined",
          "rent",
        ],
        filter: "Normal",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/Bd-UC9tgsgl/",
        location: null,
        attribution: null,
        users_in_photo: [],
      },
      {
        id: "1689231967742114744_4105735279",
        user: {
          id: "4105735279",
          full_name: "Grainger plc",
          profile_picture:
            "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
          username: "graingerplc",
        },
        images: {
          thumbnail: {
            width: 150,
            height: 150,
            url:
              "https://scontent.cdninstagram.com/vp/a93c56e9fa7271b2aae3893994e98a61/5B91FEB7/t51.2885-15/s150x150/e35/26184853_533531593680985_1559787823890956288_n.jpg",
          },
          low_resolution: {
            width: 320,
            height: 320,
            url:
              "https://scontent.cdninstagram.com/vp/f46ef3bd98d7268bab4149612d8a5e71/5B9FF9F0/t51.2885-15/s320x320/e35/26184853_533531593680985_1559787823890956288_n.jpg",
          },
          standard_resolution: {
            width: 640,
            height: 640,
            url:
              "https://scontent.cdninstagram.com/vp/629c032431f047c6547e705a25011daa/5B9FBAD2/t51.2885-15/e35/26184853_533531593680985_1559787823890956288_n.jpg",
          },
        },
        created_time: "1515592164",
        caption: {
          id: "17917482742025276",
          text:
            "New year, new home? If renting a spacious apartment  with no fees, lots of social spaces and wifi included sounds good then Argo Apartments is the place for you. 1&2 beds launched today! To enquire follow link in bio.",
          created_time: "1515592164",
          from: {
            id: "4105735279",
            full_name: "Grainger plc",
            profile_picture:
              "https://scontent.cdninstagram.com/vp/5ac80823193103cbe41db2d11d1234b4/5B7FF5A7/t51.2885-19/s150x150/14718464_1174976725914880_942589766346473472_a.jpg",
            username: "graingerplc",
          },
        },
        user_has_liked: false,
        likes: { count: 19 },
        tags: [
          "rentingredefined",
          "apartmentsforrent",
          "londonflats",
          "livingroomdecor",
          "betterrenting",
          "newhome",
          "londonapartment",
          "canningtown",
          "london",
          "socialspace",
          "rent",
        ],
        filter: "Normal",
        comments: { count: 1 },
        type: "image",
        link: "https://www.instagram.com/p/BdxW4ZUgLu4/",
        location: {
          latitude: 51.507114863624,
          longitude: -0.12731805236353,
          name: "London, United Kingdom",
          id: 213385402,
        },
        attribution: null,
        users_in_photo: [],
      },
    ],
  },
};
